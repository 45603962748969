@import "https://use.typekit.net/glp2nna.css";

@font-face {
    font-family:"ff-good-web-pro-narrow";
    src:url("./styles/fonts/good-web-pro-narrow-700.woff2") format("woff2");
    font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
    font-family:"pragmatica";
    src:url("./styles/fonts/pragmatica-400.woff2") format("woff2");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
    font-family:"pragmatica";
    src:url("./styles/fonts/pragmatica-italic-400.woff2") format("woff2");
    font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
    font-family:"pragmatica";
    src:url("./styles/fonts/pragmatica-700.woff2") format("woff2");
    font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

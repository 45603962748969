.dup-reset {
  all: revert; /* blocking inheritance for all properties */
}

@tailwind base;

@layer base {
  h1 {
    @apply font-semibold text-4xl font-primary
  }

  h2 {
    @apply font-semibold text-2xl font-primary
  }

  h3 {
    @apply font-bold text-xl
  }

  h4 {
    @apply font-bold text-base
  }
}

@tailwind components;
@tailwind utilities;



#purple-widget-unternehmensverzeichnis {
  @apply font-secondary text-base
}

.rdrDateRangePickerWrapper {
  justify-content: center;
}

.rdrDefinedRangesWrapper {
  display: none !important;
}

.rdrMonths {
  @apply md:flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .rdrMonths {
    flex-direction: row !important;
  }
}


